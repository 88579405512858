import { OrganizationAnalyticsProgramDetails } from '../components/Organization/Details';

export function OrganizationAnalyticsProgramDetailsRoute() {
  return <OrganizationAnalyticsProgramDetails />;
}

export const Component = OrganizationAnalyticsProgramDetailsRoute;

export function clientLoader() {
  return null;
}
